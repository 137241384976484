import React from 'react';
import styled from '@emotion/styled';
import ApplyTemplate from 'components/Templates/ApplyTemplate';
import { graphql, withPrefix } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import {
  IconCorp1,
  IconCorp2,
  IconCorp3,
  IconCorp4,
} from 'components/Atom/Icon';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface CompanyProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img3_1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img3_2: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img4: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img5: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img6: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img7: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const Company = ({ data, location }: CompanyProps) => {
  const title = 'VODA | 적용 | 기업';
  const desc =
    '우리 기업의 가치, 비전, 비즈니스 영역 VODA 크고 넓은 세계로 인도합니다';
  return (
    <Component>
      <Helmet>
        <title>VODA | 적용 | 기업</title>
        <meta
          name="description"
          content="우리 기업의 가치, 비전, 비즈니스 영역 VODA 크고 넓은 세계로 인도합니다"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/apply/company>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <ApplyTemplate
        location={location}
        lookingForList={{
          title: `무작정 홈페이지 트랜드만 쫒아가기?\nVODA는 기업의 목표를 먼저 생각합니다`,
          list: [
            {
              Icon: IconCorp1,
              desc: `고객 데이터를 활용한\n마케팅 전략 도출`,
            },
            {
              Icon: IconCorp2,
              desc: `고객 관심도 분석으로\n이탈 고객 최소화`,
            },
            {
              Icon: IconCorp3,
              desc: `고객 니즈를 파악하여\n유효 고객 확대`,
            },
            {
              Icon: IconCorp4,
              desc: `CTA 요소를 공략하여\n이윤 극대화`,
            },
          ],
        }}
        title="VODA 넓은 세계로\n향하다"
        header={{
          sub: 'VODA 넓은 세계로 향하다',
          main: '우리 기업의 가치, 비전,\\n비즈니스 영역…\\nVODA 크고 넓은 세계로 인도합니다',
          long: '기업의 제품과 서비스를 비롯하여 기업의 가치와 비전까지\\n고객들에게 충분히 전달되고 있을까요?\\nVODA는 사용자가 홈페이지에 머무르는 시간, 클릭 횟수, 이동 경로를\\n파악하여 고객에 대한 힌트를 드립니다.',
        }}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
        functionData={[
          {
            IllustImg: data.img1.childImageSharp.fluid,
            descData: {
              title: '기업의 기본, 빅데이터\\n사용자 수와 방문 집중 시간',
              list: [
                {
                  title: '비용은 낮추고 효율성은 높인 마케팅 전략이 있을까?',
                  desc: '시간별, 요일별, 주별, 월별 사용자 수와 방문 집중 시간 등 기초 통계 속에서 마케팅 ‘Golden time’을 찾아냅니다.',
                },
              ],
              to: '/functions/usageAnalysis',
              buttonTitle: '관련 기능 더 알아보기',
            },
            subTitle:
              '기업의 홈페이지를 가장 효과적이면서도, 혁신적인 마케팅 도구로 활용하려면?',
            mainTitle: '비즈니스 전문성을 보여주면서 경쟁력까지 갖춘 홈페이지!',
            isBackGround: false,
            isDescLeft: true,
            width: 648,
            key: '0',
          },
          {
            IllustImgList: [
              data.img3_1.childImageSharp.fluid,
              data.img3_2.childImageSharp.fluid,
            ],
            descData: {
              title: '고객에 대한 기록\\n스크롤 히트맵, 클릭 히트맵',
              list: [
                {
                  title: '고객이 가장 관심있는 부분은 무엇일까?',
                  desc: '사용자의 스크롤 정도를 추적해 어떤 영역에 오래 주목했는 지 파악할 수 있습니다.',
                },
                {
                  title: '고객이 관심을 가진 콘텐츠, 과연 클릭까지 유도했을까?',
                  desc: '사용자가 페이지를 스크롤하며 정보 탐색 중 어떤 요소에 흥미를 갖고 얼마나 클릭했는 지 알 수 있습니다.',
                },
              ],
              to: '/functions/uxAnalysis',
              buttonTitle: '관련 기능 더 알아보기',
            },
            width: 670,

            isBackGround: false,
            isDescLeft: true,
            key: '2',
          },
        ]}
        recommendList={[
          {
            title: '일반기업/제조업',
            desc: '우리기업의 독보적 제품과 서비스를\n더 많은 사람들에게 알릴 수 있습니다.\nVODA를 통해 사이트를 관리하세요.',
            img: data.img4.childImageSharp.fluid,
            key: '0',
          },
          {
            title: '병원/의료/보건분야 기업',
            desc: '혁신적인 연구 성과, 보고서, 최신소식 등\n널리 알리고 있는지 궁금하세요?\nVODA가 알려드립니다.',
            img: data.img5.childImageSharp.fluid,
            key: '1',
          },
          {
            title: '교육/컨설팅 기업',
            desc: '우리 기업의 래퍼런스와 커리큘럼,\n고객의 선택을 이끌고 있을까요?\nVODA는 고객의 선택을 이끕니다.',
            img: data.img6.childImageSharp.fluid,
            key: '2',
          },
          {
            title: '금융/법률 등 전문분야 기업',
            desc: '우리 기업의 전문성을 보다 많은 이들에게\n보다 널리 알리고 싶다면?\nVODA의 통계로 전략을 수립해보세요!',
            img: data.img7.childImageSharp.fluid,
            key: '3',
          },
        ]}
      />
    </Component>
  );
};

export default Company;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-apply@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img1: file(name: { eq: "img-userAnalytics-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3_1: file(name: { eq: "img-scroll-slice-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3_2: file(name: { eq: "img-click-slice-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img4: file(name: { eq: "img-corp-4@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img5: file(name: { eq: "img-corp-5@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img6: file(name: { eq: "img-corp-6@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img7: file(name: { eq: "img-corp-7@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
